
import { RouteStrings, RouteVariants, routeVariables } from "../routestrings"

export const getSlugAndRouteName = (routeString: string) => {
    if(!routeString) {
        return {}
    }

    let routeVAr : RouteVariants, routeName : string = '';

    const getRouteArray = () : Array<string> => {
        for (routeVAr in routeVariables) {
            let rt = routeVariables[routeVAr]
            if (typeof rt === 'string') {
                if(routeString.includes(rt)) {
                routeName = rt;
                break;
                }
            } else {
                for (let i = 0; i < rt.length; i++) {
                    if(routeString.includes(rt[i])) {
                        routeName = rt[i];
                        break;
                    }
                }
            }
        }
        return routeString.split(routeName)
    }

    const captilizeFirst = (text: string) => {
        return text.split(" ").map((item: string) => {
            return item.split('').map((char: string, index: number) => index === 0 ? char.toUpperCase() : char).join('')
        }).join(" ")
    }

    const routeArray = getRouteArray()
    let slug = routeArray.shift()?.replaceAll("-", " ") ?? '';
    let routeId = parseInt(routeArray.pop() ?? '');
    let route = routeName
    
    // slug = captilizeFirst(slug);

    return {
        slug,
        routeId,
        isIndustry : route === routeVariables.INDUSTRY,
        isSubIndustry : route === routeVariables.SUBINDUSTRY,
        isSingleCourse: route === routeVariables.COURSEDETAIL,
        isTopic : route === routeVariables.TOPICDETAIL,
        isSubTopic : route === routeVariables.SUBTOPIC,
        isArticle : route === routeVariables.ARTICLE,
        isPremium : routeVariables.PREMIUMCOURSE.includes(route),
        isIndustryPage : route === routeVariables.PREMIUMINDUSTRY,
        isSubIndustryPage : route === routeVariables.PREMIUMSUBINDUSTRY,
        isHome : (route !== routeVariables.INDUSTRY && 
            route !== routeVariables.SUBINDUSTRY && 
            route !== routeVariables.COURSEDETAIL && 
            route !== routeVariables.TOPICDETAIL && 
            route !== routeVariables.SUBTOPIC && 
            route !== routeVariables.PREMIUMINDUSTRY,
            route !== routeVariables.ARTICLE && !routeString.includes(RouteStrings.FREECOURSE) && !routeString.includes(RouteStrings.TOPICSINDEX)  )
    };
}

export const findPosition = (obj: any) => {
    var currenttop = 0;
    if (obj.offsetParent) {
        do {
            currenttop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        return currenttop;
    }
}

export const handleVettedClick = (obj: any) => {
    const position = findPosition(obj);
    window.scrollTo({
        top : (position ?? 0) - 172, 
        behavior: 'smooth',
    })
}

export const formatStringArray = (strings: string[]) => {
    // remove duplicates from the strings array
    strings = Array.from(new Set(strings));
    if (strings.length === 0) {
        return '';
    } else if (strings.length === 1) {
        return strings[0];
    } else if (strings.length === 2) {
        return strings.join(' and ');
    } else {
        let firstTwo = strings.slice(0, 2).join(', ');
        let remainingCount = strings.length - 2;
        return `${firstTwo} and ${remainingCount} more`;
    }
}
